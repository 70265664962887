import { SVGProps } from "./types";

export const ExclamationCircleFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10ZM10.875 13.5C10.875 13.9832 10.4832 14.375 10 14.375C9.51675 14.375 9.125 13.9832 9.125 13.5C9.125 13.0168 9.51675 12.625 10 12.625C10.4832 12.625 10.875 13.0168 10.875 13.5ZM10 5.625C9.51675 5.625 9.125 6.01675 9.125 6.5V10C9.125 10.4832 9.51675 10.875 10 10.875C10.4832 10.875 10.875 10.4832 10.875 10V6.5C10.875 6.01675 10.4832 5.625 10 5.625Z"
            fill="currentColor"
        />
    </svg>
);
