import { SVGProps } from "./types";

export const UsersUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10 4.05325C10.5701 3.40736 11.4041 3 12.3333 3C14.0516 3 15.4444 4.39289 15.4444 6.11111C15.4444 7.82933 14.0516 9.22222 12.3333 9.22222C11.4041 9.22222 10.5701 8.81486 10 8.16897M12.3333 17H3V16.2222C3 13.6449 5.08934 11.5556 7.66667 11.5556C10.244 11.5556 12.3333 13.6449 12.3333 16.2222V17ZM12.3333 17H17V16.2222C17 13.6449 14.9107 11.5556 12.3333 11.5556C11.4833 11.5556 10.6864 11.7828 10 12.1799M10.7778 6.11111C10.7778 7.82933 9.38489 9.22222 7.66667 9.22222C5.94845 9.22222 4.55556 7.82933 4.55556 6.11111C4.55556 4.39289 5.94845 3 7.66667 3C9.38489 3 10.7778 4.39289 10.7778 6.11111Z"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const UsersFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.22222 6.88887C9.22222 8.17754 8.17755 9.2222 6.88888 9.2222C5.60022 9.2222 4.55555 8.17754 4.55555 6.88887C4.55555 5.60021 5.60022 4.55554 6.88888 4.55554C8.17755 4.55554 9.22222 5.60021 9.22222 6.88887Z"
            fill="currentColor"
        />
        <path
            d="M15.4444 6.88887C15.4444 8.17754 14.3998 9.2222 13.1111 9.2222C11.8224 9.2222 10.7778 8.17754 10.7778 6.88887C10.7778 5.60021 11.8224 4.55554 13.1111 4.55554C14.3998 4.55554 15.4444 5.60021 15.4444 6.88887Z"
            fill="currentColor"
        />
        <path
            d="M12.2782 15.4444C12.3145 15.1904 12.3333 14.9307 12.3333 14.6666C12.3333 13.3948 11.8973 12.2249 11.1665 11.2981C11.7385 10.9672 12.4027 10.7778 13.1111 10.7778C15.2589 10.7778 17 12.5189 17 14.6666V15.4444H12.2782Z"
            fill="currentColor"
        />
        <path
            d="M6.88888 10.7778C9.03666 10.7778 10.7778 12.5189 10.7778 14.6666V15.4444H3V14.6666C3 12.5189 4.74111 10.7778 6.88888 10.7778Z"
            fill="currentColor"
        />
    </svg>
);
