import { SVGProps } from "./types";

export const HomeUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 10L4.55556 8.44444M4.55556 8.44444L10 3L15.4444 8.44444M4.55556 8.44444V16.2222C4.55556 16.6518 4.90378 17 5.33333 17H7.66667M15.4444 8.44444L17 10M15.4444 8.44444V16.2222C15.4444 16.6518 15.0962 17 14.6667 17H12.3333M7.66667 17C8.09622 17 8.44444 16.6518 8.44444 16.2222V13.1111C8.44444 12.6816 8.79267 12.3333 9.22222 12.3333H10.7778C11.2073 12.3333 11.5556 12.6816 11.5556 13.1111V16.2222C11.5556 16.6518 11.9038 17 12.3333 17M7.66667 17H12.3333"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const HomeFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.6187 3.25628C10.277 2.91457 9.72299 2.91457 9.38128 3.25628L3.25628 9.38128C2.91457 9.72299 2.91457 10.277 3.25628 10.6187C3.59799 10.9604 4.15201 10.9604 4.49372 10.6187L4.75 10.3624V16.125C4.75 16.6082 5.14175 17 5.625 17H7.375C7.85825 17 8.25 16.6082 8.25 16.125V14.375C8.25 13.8918 8.64175 13.5 9.125 13.5H10.875C11.3582 13.5 11.75 13.8918 11.75 14.375V16.125C11.75 16.6082 12.1418 17 12.625 17H14.375C14.8582 17 15.25 16.6082 15.25 16.125V10.3624L15.5063 10.6187C15.848 10.9604 16.402 10.9604 16.7437 10.6187C17.0854 10.277 17.0854 9.72299 16.7437 9.38128L10.6187 3.25628Z"
            fill="currentColor"
        />
    </svg>
);
