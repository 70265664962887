import { ReactNode } from "react";
import Link from "next/link";
import { UrlObject } from "url";
import { cx } from "class-variance-authority";

declare type Url = string | UrlObject;
const styles = {
    rootContainer: "flex flex-col items-center justify-center gap-6 mt-6",
    infoContainer: "flex flex-col gap-2 items-center",
    detailContainer: "flex flex-col gap-1 text-center",
    detailTitle: "text-[16px] font-semibold text-blue-grey-600",
    detailContent: "italic text-blue-grey-400",
    buttonContainer: "flex justify-center cursor-pointer",
    buttonText: "text-primary-600 typography-h5 font-semibold",
};

type EmptyProps = {
    title: string;
    description: string;
    link?: Url;
    icon?: ReactNode;
    ctaText?: string;
    center?: boolean;
};

export const Empty = ({
    title,
    description,
    link,
    icon,
    ctaText,
    center = false,
}: EmptyProps): JSX.Element => {
    return (
        <div
            className={cx(
                styles.rootContainer,
                center && "relative top-[100px] md:top-0 lg:h-[500px]",
            )}
        >
            <div className={styles.infoContainer}>
                {icon}
                <div className={styles.detailContainer}>
                    <div className={styles.detailTitle}>{title}</div>
                    <div className={styles.detailContent}>{description}</div>
                </div>
            </div>
            {link && (
                <div className={styles.buttonContainer}>
                    <Link href={link} passHref>
                        <div className={styles.buttonText}>{ctaText}</div>
                    </Link>
                </div>
            )}
        </div>
    );
};
