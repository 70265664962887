import { memo } from "react";
import Link from "next/link";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { Badge } from "components/Badge";
import { Skeleton } from "components/Skeleton";

import dayjs from "lib/dayjs";
import { useQuery } from "@apollo/client";
import { graphql } from "lib/gql";
import { colourCss } from "config/theme";
import { middot } from "assets/middot";

const styles = {
    rootContainer: css`
        background: #ffffff;
        /* Grey Light */
        border-bottom: 1px solid #e2e2e2;
        box-sizing: border-box;
        padding: 8px 0;
        cursor: pointer;
    `,
    actionContainer: css`
        padding: 6px 0;
    `,
    actionText: css([
        colourCss.secondaryBright,
        { fontWeight: 700, fontSize: 15, fontFamily: "Lato" },
    ]),
    textContainer: css`
        padding: 12px 0;
    `,
    fontWeight: css`
        font-weight: 700;
    `,
    textFont: css`
        font-size: 15px;
        font-family: Lato;
    `,
    titleFont: css`
        font-size: 17px;
        font-weight: bold;
        font-family: Lato;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `,
    paddingBottom: css`
        padding-bottom: 16px;
    `,
};

const bookingCardQuery = graphql(`
    query bookingCard($tenantId: ID!) {
        organisation(uid: $tenantId) {
            uid
            name
        }
    }
`);

type GroupedBookingDetails = {
    tenantId: string;
    categoryName?: string;
    start: string;
    end: string;
    confirmed?: string | null;
    courtNames: string;
    bookingIds: string[];
    isAllCancelled: boolean;
    isDailyService: boolean;
};
type BookingCardProps = {
    groupedBookingDetails: GroupedBookingDetails;
};
const BookingCard = memo(
    ({ groupedBookingDetails }: BookingCardProps): JSX.Element => {
        const { t, ready } = useTranslation("common");

        const { data, loading } = useQuery(bookingCardQuery, {
            variables: { tenantId: groupedBookingDetails.tenantId },
        });

        const categoryName = groupedBookingDetails.categoryName;

        if (loading || !data || !ready) {
            return (
                <div css={styles.rootContainer} data-attr="booking-card">
                    <Skeleton paragraph={{ rows: 2 }} />
                </div>
            );
        }

        const timeText = groupedBookingDetails.isDailyService
            ? `${fmt(groupedBookingDetails.start, "DD MMM YYYY")} - ${fmt(
                  groupedBookingDetails.end,
                  "DD MMM YYYY",
              )}`
            : `${fmt(
                  groupedBookingDetails.start,
                  "DD MMM YYYY, h:mmA",
              )} - ${fmt(groupedBookingDetails.end, "h:mmA")}`;

        const isUnconfirmed =
            !groupedBookingDetails.confirmed &&
            !groupedBookingDetails.isAllCancelled;

        return (
            <Link
                href={{
                    pathname: "/user/bookings",
                    query: {
                        bookingIds: groupedBookingDetails.bookingIds,
                    },
                }}
                passHref
            >
                <div css={styles.rootContainer}>
                    <div className="flex justify-between align-middle">
                        <div css={styles.titleFont}>
                            {data.organisation.name}
                        </div>
                        {isUnconfirmed ? (
                            <Badge className="w-fit normal-case" color="red">
                                {t("tagsv2_due", "Due")}
                            </Badge>
                        ) : groupedBookingDetails.isAllCancelled ? (
                            <Badge className="w-fit normal-case" color="light">
                                {t("tagsv2_cancelled", "Cancelled")}
                            </Badge>
                        ) : undefined}
                    </div>
                    <div css={styles.textContainer}>
                        <div css={styles.textFont}>{timeText}</div>
                        <div>
                            <span css={styles.textFont}>
                                {categoryName}
                                {middot}
                                {groupedBookingDetails.courtNames}
                            </span>
                        </div>
                    </div>
                    <div css={styles.actionContainer}>
                        <span css={styles.actionText}>
                            {t("view_more", "View more")} &gt;
                        </span>
                    </div>
                </div>
            </Link>
        );
    },
);
BookingCard.displayName = "BookingCard";

export default BookingCard;

function fmt(d: string, fmt: string): string {
    return dayjs(d).tz().format(fmt);
}
