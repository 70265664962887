import { VariantProps, cva, cx } from "class-variance-authority";
import Link from "next/link";
import { useTranslation } from "react-i18next";

import { Skeleton } from "components/Skeleton";
import { Badge, BookingState, BookingStatusBadge } from "components/Badge";
import { ArrowRightUnfilled } from "components/Icons";

import { useQuery } from "@apollo/client";
import { unique } from "utils/index";
import { UrlObject } from "url";
import { graphql } from "lib/gql";
import { addMinutes, isPast } from "date-fns";
import { toDate } from "date-fns-tz";
import { fmtDtKL } from "lib/date-fns-util";

type Url = string | UrlObject;

type RootContainerVariantProps = VariantProps<typeof rootContainerVariants>;
const rootContainerVariants = cva(
    "flex flex-col justify-between rounded-xl border border-solid bg-white p-3",
    {
        variants: {
            borderColor: {
                light: "border-blue-grey-50",
                dark: "border-blue-grey-100",
            },
        },
        defaultVariants: {
            borderColor: "light",
        },
    },
);
type Resource = { uid: string; name: string };
export type HomepageUpcomingCardProps = {
    startDt: string;
    endDt: string;
    categoryName: string;
    centreName: string;
    resources: Resource[];
    href: Url;
    confirmed?: string | null;
    isAllCancelled: boolean;
    rescheduledBooking: boolean;
    workflowId?: string;
    loading: boolean;
} & RootContainerVariantProps;
export const HomepageUpcomingCard = ({
    startDt,
    endDt,
    categoryName,
    centreName,
    resources,
    href,
    confirmed,
    isAllCancelled,
    rescheduledBooking,
    workflowId,
    loading,
    borderColor,
}: HomepageUpcomingCardProps): JSX.Element => {
    const { t, ready } = useTranslation(["components/HomePage", "common"]);
    const day = fmtDtKL(startDt, "E");
    const date = fmtDtKL(startDt, "d");
    const month = fmtDtKL(startDt, "LLL");
    const time = `${fmtDtKL(startDt, "h:mma")} - ${fmtDtKL(endDt, "h:mma")}`;
    const resourceNames = unique(resources.map((r) => r.name)).join(", ");

    const { data: workflowData } = useQuery(webCheckoutQuery, {
        variables: { workflowId: workflowId ?? "" },
        fetchPolicy: "cache-and-network",
        skip:
            rescheduledBooking || !workflowId || !!confirmed || isAllCancelled,
    });

    const { data: rescheduleWorkflowData } = useQuery(
        webCheckoutRescheduleQuery,
        {
            variables: { workflowId: workflowId ?? "" },
            fetchPolicy: "cache-and-network",
            skip:
                !rescheduledBooking ||
                !workflowId ||
                !!confirmed ||
                isAllCancelled,
        },
    );
    const isUnconfirmed = !confirmed && !isAllCancelled;
    const rescheduleWorkflow = {
        ...rescheduleWorkflowData?.courtsiteRescheduleStateById,
        hasDeposit: false,
    };
    const workflow =
        workflowData?.courtsiteCheckoutStateById || rescheduleWorkflow;
    const workflowCreated = workflow.created;
    const paymentCheckoutTime =
        workflowCreated && addMinutes(toDate(workflowCreated), 8);

    const paymentUnavailable =
        !paymentCheckoutTime || isPast(paymentCheckoutTime);

    let paymentLink: UrlObject | false = false;
    const query = rescheduledBooking
        ? { workflowId }
        : { workflowID: workflowId };
    if (workflowId && !paymentUnavailable)
        paymentLink = {
            pathname: rescheduledBooking
                ? "/reschedule/checkout/[workflowId]"
                : "/checkout/[workflowID]/payment",
            query,
        };

    let state: BookingState = "pending";
    if (confirmed) state = "confirmed";
    else if (
        !!workflow.hasDeposit &&
        ((isUnconfirmed && paymentUnavailable) ||
            workflow.state === "AWAITING_REMAINING_PAYMENTS")
    )
        state = "paymentDue";
    else if (isAllCancelled || paymentUnavailable) state = "cancelled";

    if (loading || !ready) {
        return (
            <div className={cx(rootContainerVariants({ borderColor }))}>
                <Skeleton paragraph={{ rows: 2 }} />
            </div>
        );
    }

    const child = (
        <div
            className={cx(
                rootContainerVariants({ borderColor }),
                "cursor-pointer",
            )}
        >
            <div className="mb-3 flex divide-x divide-y-0 divide-solid divide-blue-grey-100">
                <div className="flex w-fit flex-col items-center pr-3">
                    <div className="typography-micro lowercase text-blue-grey-400">
                        {day}
                    </div>
                    <div className="typography-h1 -mt-2 font-bold leading-[44.8px] text-primary-500">
                        {date}
                    </div>
                    <div className="typography-overline -mt-1 tracking-widest text-blue-grey-600">
                        {month}
                    </div>
                </div>
                <div className="min-w-0 pl-3">
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col gap-1">
                            <div className="flex gap-2">
                                <Badge color="light">{categoryName}</Badge>
                                <BookingStatusBadge status={state}>
                                    {t(
                                        "common:tagsv2",
                                        state.toLocaleUpperCase(),
                                        {
                                            context: state,
                                        },
                                    )}
                                </BookingStatusBadge>
                            </div>
                            <div className="typography-h4 line-clamp-2 flex-1 font-bold text-blue-grey-900">
                                {centreName}
                            </div>
                        </div>
                        <div>
                            <div className="typography-h5 text-blue-grey-500">
                                {time}
                            </div>
                            <div className="typography-h5 line-clamp-1 text-wrap text-blue-grey-500">
                                {resourceNames}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {state === "pending" && (
                <div className="typography-h5 flex cursor-pointer gap-2 font-semibold text-primary-600">
                    {t("common:proceed_to_payment", "Proceed to payment")}
                    <div className="flex size-6 items-center justify-center rounded-full bg-blue-grey-50">
                        <ArrowRightUnfilled className="size-3 text-primary-600" />
                    </div>
                </div>
            )}
            {state === "confirmed" && (
                <div className="typography-h5 flex cursor-pointer gap-2 font-semibold text-primary-600">
                    {t("extendSession", "Extend my session")}
                    <div className="flex size-6 items-center justify-center rounded-full bg-blue-grey-50">
                        <ArrowRightUnfilled className="size-3 text-primary-600" />
                    </div>
                </div>
            )}
        </div>
    );

    if (state === "pending" && paymentLink)
        return <Link href={paymentLink}>{child}</Link>;
    return <Link href={href}>{child}</Link>;
};

const webCheckoutQuery = graphql(`
    query homepageUpcomingCardWebCheckout($workflowId: ID!) {
        courtsiteCheckoutStateById(checkoutId: $workflowId) {
            id
            cancelled
            expired
            created
            hasDeposit
            state
        }
    }
`);
const webCheckoutRescheduleQuery = graphql(`
    query homepageUpcomingCardWebCheckoutReschedule($workflowId: ID!) {
        courtsiteRescheduleStateById(workflowId: $workflowId) {
            id
            cancelled
            expired
            created
            state
        }
    }
`);
