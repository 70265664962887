import { useCallback, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useAuth } from "lib/firebase/hooks";
import { BookingUnconfirmedHomePage } from "components/BookingUnconfirmed";
import dayjs from "lib/dayjs";
import { graphql } from "lib/gql";
import { PendingPaymentsQuery } from "lib/gql/graphql";
import { addDays, startOfToday } from "date-fns";

export const PendingPayments = ({
    isDesktop,
}: {
    isDesktop: boolean;
}): JSX.Element => {
    const { unconfirmedBookings } = usePendingPaymentsQuery();
    if (!unconfirmedBookings.length) return <div />;
    return (
        <BookingUnconfirmedHomePage
            isDesktop={isDesktop}
            unconfirmedBookings={unconfirmedBookings}
        />
    );
};

const pendingPaymentsQuery = graphql(`
    query pendingPayments($startAfter: DateTime) {
        myInvoices(filter: { byStatus: { unpaid: true } }) {
            uid
            created
            items {
                uid
                metadata
                relatedEntity {
                    uid
                    entityType
                    entityId
                }
            }
        }
        myBookings(
            limit: 30
            filter: {
                byStatus: { unconfirmed: true }
                byStartDateAfter: $startAfter
            }
        ) {
            edges {
                node {
                    uid
                    tenantId
                    referenceId
                    created
                    startDt
                    endDt
                    confirmed
                    cancelled
                    metadata
                    resources {
                        uid
                        resource {
                            uid
                            name
                            archived
                        }
                    }
                    service {
                        uid
                        serviceMode
                        category {
                            uid
                            name
                        }
                    }
                }
            }
        }
    }
`);

type InvoiceItem = NonNullable<
    PendingPaymentsQuery["myInvoices"]
>[0]["items"][0];
type BookingInvoiceItem = InvoiceItem & {
    relatedEntity: { entityType: "BOOKING" };
};
type BaseHomeBooking = PendingPaymentsQuery["myBookings"]["edges"][0]["node"];
type HomeBooking = Omit<BaseHomeBooking, "metadata"> & {
    metadata: { price?: string; workflowId?: string };
};
type UsePendingPaymentsData = {
    unconfirmedBookings: HomeBooking[];
    loading: boolean;
};
const usePendingPaymentsQuery = (): UsePendingPaymentsData => {
    const { user } = useAuth();
    const isLoggedIn = user && user.uid;

    // The startAfter filter is to improve the efficiecy of the query by
    // reducing no. of bookings checked as unconfirmed and as to not impact business logic,
    // bookings 7 days before today till the future will be queried
    const { data, loading } = useQuery(pendingPaymentsQuery, {
        variables: {
            startAfter: addDays(startOfToday(), -7).toISOString(),
        },
        skip: !isLoggedIn,
        fetchPolicy: "no-cache",
    });
    const awaitingRemainderBookingIds = useMemo(() => {
        if (!data?.myInvoices) return [];

        const remainderIds = data.myInvoices.flatMap((i) =>
            i.items
                .filter(
                    (ii): ii is BookingInvoiceItem =>
                        ii.relatedEntity?.entityType === "BOOKING" &&
                        JSON.parse(ii.metadata).partialPayment === "remainder",
                )
                .map((ii) => ii.relatedEntity.entityId),
        );
        const depositIds = data.myInvoices.flatMap((i) =>
            i.items
                .filter(
                    (ii): ii is BookingInvoiceItem =>
                        ii.relatedEntity?.entityType === "BOOKING" &&
                        JSON.parse(ii.metadata).partialPayment === "deposit",
                )
                .map((ii) => ii.relatedEntity.entityId),
        );
        return remainderIds.filter((id) => !depositIds.includes(id));
    }, [data]);

    const filterFn = useCallback(
        (b) => {
            const now = dayjs().unix();
            if (awaitingRemainderBookingIds.includes(b.uid)) {
                // include if booking starts within next 24 hours
                return dayjs(b.startDt).unix() - now < 24 * 3600;
            }
            // include if booking created in the last 8 minutes
            return now - dayjs(b.created).unix() < 8 * 60;
        },
        [awaitingRemainderBookingIds],
    );

    const bookings = useMemo(() => {
        const bookingsEdge = data?.myBookings.edges || [];
        return bookingsEdge
            .filter(
                ({ node }) =>
                    dayjs(node.endDt).isAfter(dayjs()) &&
                    !node.cancelled &&
                    filterFn(node),
            )
            .map(({ node }) => {
                type BookingMetadata = { price?: string; workflowId?: string };
                const metadata: BookingMetadata = JSON.parse(node.metadata);

                return {
                    uid: node.uid,
                    created: node.created,
                    tenantId: node.tenantId,
                    referenceId: node.referenceId,
                    startDt: node.startDt,
                    endDt: node.endDt,
                    confirmed: node.confirmed,
                    cancelled: node.cancelled,
                    metadata: metadata,
                    resources: node.resources,
                    service: node.service,
                };
            });
    }, [data, filterFn]);

    const updatedMetadataBookings = useMemo(() => {
        return bookings.map((b) => {
            if (!awaitingRemainderBookingIds.includes(b.uid)) return b;
            return {
                ...b,
                metadata: {
                    ...b.metadata,
                    invoiceId: data?.myInvoices?.find((i) =>
                        i.items.some(
                            (ii) => ii.relatedEntity?.entityId === b.uid,
                        ),
                    )?.uid,
                },
            };
        });
    }, [data, bookings, awaitingRemainderBookingIds]);

    return { unconfirmedBookings: updatedMetadataBookings, loading };
};
