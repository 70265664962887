import { cx } from "class-variance-authority";
import { ReactNode } from "react";

export const Tag = ({
    className,
    children,
}: {
    className?: string;
    children: ReactNode;
}): JSX.Element => {
    return (
        <div
            className={cx(
                "text-nowrap rounded border px-1.5 py-0.5 text-tiny",
                className,
            )}
        >
            {children}
        </div>
    );
};
